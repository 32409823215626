import { Component, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DepositWithdrawDialogComponent } from '../dialogs/deposit-withdraw-dialog/deposit-withdraw-dialog.component';
import { LoginService } from 'src/app/services/login.service';
import { TopbarService } from 'src/app/services/topbar.service';
import { langCodes } from './config';
import { BehaviorSubject, take, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { IframeService } from 'src/app/codigree/iframe.service';
import { ILoginResponse } from 'src/app/models/login';
import { IBalanceRequest, IBalanceResponse } from 'src/app/models/balance';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent extends BaseComponent implements OnInit {

  isLoggedIn: boolean;
  firstName;
  balance;
  ccy;
  updatedBalance: string | number;
  langCodesList = langCodes;

  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
    private iframeService: IframeService,
    private topbarService: TopbarService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loginService.isLogIn()
      .pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.isLoggedIn = value;
        if (value) {
          this.loginService.loggedUser$.subscribe(
            (loginRes: ILoginResponse) => {
              if (loginRes) {
                this.firstName = loginRes.firstName;
                this.balance = loginRes.availableBalance;
                this.ccy = loginRes.currency;
              }

            }
          )

        }
      });
  }

  updateLanguage(value: string) {
    this.topbarService.setEwlCookie(this.topbarService.LANG_COOKIE_NAME, value);
  }

  openDialog(user: IUser, mod: string) {
    const dialogRef = this.dialog.open(DepositWithdrawDialogComponent, {
      disableClose: false,
      data: { user, mod },
      panelClass: 'deposit-withdraw',
      height: '300px',
      width: '400px'
    });

    dialogRef.afterClosed().pipe(
      take(1),
    ).subscribe((res: IBalanceRequest) => {
      this.loginService.putCashBalance(res).subscribe(
        {
          next: (balanceRes: IBalanceResponse) => {
            let user: ILoginResponse = this.loginService.getUserFromLocalStorage();
            user.availableBalance = balanceRes.availableBalance;
            this.loginService.updateUser(user);
          },
          error: (e: Error) => {

          }
        })
    });
  }

  updateBalance() {
    this.loginService.getCashBalance().subscribe(
      {
          next: (balanceRes: IBalanceResponse) => {
            let user: ILoginResponse = this.loginService.getUserFromLocalStorage();
            user.availableBalance = balanceRes.availableBalance;
            this.loginService.updateUser(user);
          },
          error: (e: Error) => {

         }
      }
    ); 
  }

  onLogout() {
    this.loginService.doLogoutUser();
    this.iframeService.frameBase.searchParams.delete('token');
  }

  toggleMenu() {
    this.topbarService.toggle();
  }

}

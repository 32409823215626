import { Component } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { TopbarService } from 'src/app/services/topbar.service';
import { langCodes } from '../topbar/config';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { take, takeUntil } from 'rxjs';
import { ILoginResponse } from 'src/app/models/login';
import { IBalanceRequest, IBalanceResponse } from 'src/app/models/balance';
import { MatDialog } from '@angular/material/dialog';
import { IframeService } from 'src/app/codigree/iframe.service';
import { DepositWithdrawDialogComponent } from '../dialogs/deposit-withdraw-dialog/deposit-withdraw-dialog.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent {
  isSidebarVisible = false;
  isLoggedIn: boolean;
  firstName;
  balance;
  ccy;
  updatedBalance: string | number;
  langCodesList = langCodes;
  constructor(
    private topbarService: TopbarService,
    private loginService: LoginService,
    private dialog: MatDialog,
    private iframeService: IframeService
  ) {
    super();
  }
  ngOnInit(): void {
    this.loginService
      .isLogIn()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.isLoggedIn = value;
        if (value) {
          this.loginService.loggedUser$.subscribe(
            (loginRes: ILoginResponse) => {
              if (loginRes) {
                this.firstName = loginRes.firstName;
                this.balance = loginRes.availableBalance;
                this.ccy = loginRes.currency;
              }
            }
          );
        }
      });
    this.topbarService
      .getIsOpen$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        setTimeout(() => {
          this.isSidebarVisible = res;
        });
      });
  }

  updateLanguage(value: string) {
    this.topbarService.setEwlCookie(this.topbarService.LANG_COOKIE_NAME, value);
  }

  openDialog(user: IUser, mod: string) {
    const dialogRef = this.dialog.open(DepositWithdrawDialogComponent, {
      disableClose: false,
      data: { user, mod },
      panelClass: 'deposit-withdraw',
      height: '300px',
      width: '410px'
    });

    dialogRef.afterClosed().pipe(
      take(1),
    ).subscribe((res: IBalanceRequest) => {
      this.loginService.putCashBalance(res).subscribe(
        {
          next: (balanceRes: IBalanceResponse) => {
            let user: ILoginResponse = this.loginService.getUserFromLocalStorage();
            user.availableBalance = balanceRes.availableBalance;
            this.loginService.updateUser(user);
          },
          error: (e: Error) => {

          }
        })
    });
  }

  updateBalance() {
    this.loginService.getCashBalance().subscribe(
      {
          next: (balanceRes: IBalanceResponse) => {
            let user: ILoginResponse = this.loginService.getUserFromLocalStorage();
            user.availableBalance = balanceRes.availableBalance;
            this.loginService.updateUser(user);
          },
          error: (e: Error) => {

         }
      }
    ); 
  }

  onLogout() {
    this.loginService.doLogoutUser();
    this.iframeService.frameBase.searchParams.delete('token');
  }

  handleClickOutside(event) {
    if (event.target.classList?.contains('showHideAccount')) {
      return;
    }
    if (this.isSidebarVisible) {
      this.topbarService.close();
    }
  }

  onSwipeLeft() {
    this.topbarService.close();
  }

  get sidebarStateName(): string {
    return this.isSidebarVisible ? 'show' : 'hide';
  }
}

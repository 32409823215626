<div
  class="sidebar-nav"
  (swipeleft)="onSwipeLeft()"
  [class.visible]="isSidebarVisible"
>
  <div *ngIf="!isLoggedIn">
    <div class="items">
        <div class="lang">
            <label for="dropdown" style="font-size: 13px">Lang: </label>
            <select class="dropdown" (change)="updateLanguage($event.target.value)">
              <option
                *ngFor="let lang of langCodesList | keyvalue"
                [value]="lang.key"
                [selected]="lang.value.code == topbarService.selectedLang"
              >
                {{ lang.value.label }}
              </option>
            </select>
        </div>
        <app-login-form> </app-login-form>
    </div>
  </div>
  <div *ngIf="isLoggedIn">
    <div class="items">
        <div class="lang">
            <label for="dropdown" style="font-size: 13px">Lang: </label>
            <select class="dropdown" (change)="updateLanguage($event.target.value)">
              <option
                *ngFor="let lang of langCodesList | keyvalue"
                [value]="lang.key"
                [selected]="lang.value.code == topbarService.selectedLang"
              >
                {{ lang.value.label }}
              </option>
            </select>
        </div>
        <div class="user-info" style="align-items: center">
            <table style="width: auto">
              <tr>
                <td>{{ "Username" | translate }}:</td>
                <td>{{ firstName }}</td>
              </tr>
              <tr>
                <td>{{ "Balance" | translate }}:</td>
                <td>{{ balance }} {{ ccy }}</td>
              </tr>
            </table>
            <div class="btn-container" style="display: flex; flex-direction: row">
              <button class="balance-btn" (click)="updateBalance()">
                Update Balance
              </button>
              <button (click)="onLogout()" class="logout-btn">
                {{ "Logout" | translate }}
              </button>
            </div>
          </div>
          <div class="action-box">
            <button
              class="action-button"
              (click)="openDialog(user, 'Deposit')"
              title="Deposit"
            >
              {{ "Deposit" | translate }}
            </button>
            <button
              class="action-button"
              (click)="openDialog(user, 'Withdraw')"
              title="Withdraw"
            >
              {{ "Withdraw" | translate }}
            </button>
    </div>
  </div>
</div>
<div
  [class.visible]="isSidebarVisible"
  class="overlay"
  (click)="isSidebarVisible && handleClickOutside($event)"
></div>
